import numbro from 'numbro';
import { isNotSet, isTrue } from '@/utils/formatChecker';

export const formatter = {
  format: (n: number) => {
    return numbro(n).format({ thousandSeparated: true });
  },
};

const potionCategory = [
  '公寓',
  '公寓(5樓含以下無電梯)',
  '大樓',
  '住宅大樓(11層含以上有電梯)',
  '華廈',
  '華廈(10層含以下有電梯)',
  '店面',
  '店面(店鋪)',
  '辦公商業大樓',
  '套房(1房1廳1衛)',
];
const commonCategory = ['透天', '透天厝', '別墅', '別墅 / 透天厝', '土地', '工廠', '廠辦', '農舍', '倉庫', '其他'];

const WAN = 1e4;
const YI = 1e8;
export const countryLabelMap: Map<
  string,
  {
    areaUnit: string;
    areaLabel: string;
    dollar: string;
    priceLabel: string;
    dealPriceLabel: string;
  }
> = new Map([
  [
    'US',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '美金',
      priceLabel: '開價 (美金)',
      dealPriceLabel: '成交價 (美金)',
    },
  ],
  [
    'CA',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '加幣',
      priceLabel: '開價 (加幣)',
      dealPriceLabel: '成交價 (加幣)',
    },
  ],
  [
    'TW',
    {
      areaUnit: '坪',
      areaLabel: '總坪數',
      dollar: '',
      priceLabel: '開價',
      dealPriceLabel: '成交價',
    },
  ],
]);
export const chineseNumeralFormatter = (n: number): string => {
  if (n < YI) {
    return `$${formatter.format(n / WAN)} 萬`;
  } else {
    return `$${formatter.format(n / YI)} 億`;
  }
};

export const priceLabelFormatter = (country: string) => {
  return `${countryLabelMap.get(country)?.priceLabel}`;
};
export const areaFormatter = (
  input: string | null,
  { omit = [], country = 'TW' }: { omit?: Array<string | null>; country: string }
): string | null | number => {
  if (isNotSet(input)) return null;

  let result = Number.isNaN(parseFloat(input)) ? 0 : parseFloat(input);
  omit.forEach((value) => {
    if (isNotSet(value)) return;
    let num = Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    result = result - num;
  });

  if (country === 'TW') {
    return `${result.toFixed(2)}`;
  } else {
    return `${result}`;
  }
};
export const unitAreaLabelFormatter = (country: string): string => {
  return `${countryLabelMap.get(country)?.areaUnit}`;
};
export const areaLabelFormatter = (country: string): string => {
  return `${countryLabelMap.get(country)?.areaLabel}`;
};
export const dollarLabelFormatter = (country: string, defaultUnit?: string): string => {
  return `${countryLabelMap.get(country)?.dollar}` || defaultUnit || '';
};
export const dealPriceLabelFormatter = (country: string): string => {
  return `${countryLabelMap.get(country)?.dealPriceLabel}`;
};

export const itemHasOrNotFormatter = (input: boolean | null): string => `${isTrue(input) ? '有' : '無'}`;

export const lotSizeTitleLangKeyFormatter = (type: string | null) => {
  if (potionCategory.some((category) => category === type)) {
    return 'detail_portion_lot_size';
  } else {
    return 'detail_lot_size';
  }
};
