export default function serializer<Input extends Record<string, any>>(
  input: Input
): ReplaceValueType<Input, Date, number> {
  return Object.entries(input)
    .filter(([_, value]) => value instanceof Date)
    .reduce(
      (prev, [key, value]: [key: string, value: Date]) => ({
        ...prev,
        [key]: value.getTime(),
      }),
      input
    );
}
