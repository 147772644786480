import Image from 'next/image';

type Props = {
  url: string;
  alt: string;
  priority?: boolean;
  sizes: string | undefined;
};

export default function HouseImageSlide({ url, alt, priority = false, sizes = '100vw' }: Props) {
  return (
    <>
      <Image
        sizes={sizes}
        priority={priority}
        className='bg-black'
        src={url}
        alt={alt}
        width={3}
        height={2}
        objectFit={'contain'}
        layout={'responsive'}
      />
    </>
  );
}
