import { ListingImage } from '@/swagger';

export const listingImageSorter = (images: Array<ListingImage>): Array<ListingImage> => {
  const inOrder = images.sort((a, b) => a.order! - b.order!);
  const withTag: Array<ListingImage> = [];
  const withoutTag: Array<ListingImage> = [];
  inOrder.forEach((image) => {
    if (image.tag) withTag.push(image);
    else withoutTag.push(image);
  });
  return withoutTag.concat(withTag);
};
