import Image from 'next/image';

import { FC, useCallback } from 'react';
import useFavorites from '@/src/hooks/useFavorites';
import AddFavoriteButtonProps from '@/src/components/UI/AddFavoriteButtonProps';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';

const AddFavoriteButton: FC<AddFavoriteButtonProps> = ({ sid, inCard }) => {
  const { sids, add, remove } = useFavorites();
  const isFavorite = sids.includes(sid);

  const handlePressFavorite = useCallback(
    (e) => {
      e.preventDefault();
      if (isFavorite) {
        remove(sid);
      } else {
        add(sid);
      }
    },
    [add, remove, isFavorite, sid]
  );

  return (
    <div
      data-tip='加入最愛'
      className={`${!inCard ? 'w-6 h-6 sm:w-12 sm:h-12 justify-center items-center flex hover:bg-gray100' : ''}`}
      onClick={handlePressFavorite}
    >
      {inCard ? (
        <Iconfavorite isFavorite={isFavorite} />
      ) : (
        <Image
          data-tip='加入最愛'
          width={24}
          height={24}
          objectFit={'contain'}
          src={isFavorite ? '/icons-favorite-filled.png' : '/icons-favorite.png'}
          alt='加入最愛'
        />
      )}
    </div>
  );
};
const Iconfavorite = ({ isFavorite }: { isFavorite: boolean }) => {
  if (isFavorite) {
    return <AiFillHeart className={'text-xl text-brand'} />;
  }
  return <AiOutlineHeart className={'text-xl'} />;
};
export default AddFavoriteButton;
