import React, { useCallback } from 'react';
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
// @ts-ignore
import { ShareSocial as RShareSocial } from 'react-share-social';
import { NOOP } from '@/constants/noop';
import { IoMdShare } from 'react-icons/io';

interface Props {
  url: string;
  title?: string;
  text?: string;
  className?: string;
  buttonClassName?: string;
}

export const ShareSocial: React.FC<Props> = ({ url, title, text, className, buttonClassName }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      event.preventDefault();
      if (navigator.share) {
        try {
          const shareData: ShareData = {
            title,
            url,
            text,
          };
          await navigator.share(shareData);
        } catch (error) {}
      } else {
        onOpen();
      }
    },
    [url, title, text]
  );

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <RShareSocial
            title={`${title}`}
            url={url}
            socialTypes={['facebook', 'twitter', 'line']}
            onSocialButtonClicked={NOOP}
          />
        </ModalContent>
      </Modal>
      <button onClick={onClick} className={buttonClassName}>
        <IoMdShare className={className} />
      </button>
    </React.Fragment>
  );
};
