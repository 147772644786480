import { isNotEmptyArray, isNotEmptyString, isSet } from './formatChecker';
import { IListingInBuyPage } from '@/src/components/UI/HouseDetail/HouseDetailContainerProps';
import { areaFormatter } from '@/src/libs/number-helper';
import { BuildingCountry, ListingDetail } from '@/swagger';

export const getFloorInformation = ({ floor, total_floor, floor_note, detail_floor }: IListingInBuyPage) => {
  if (isSet(floor_note)) return floor_note;
  else if (isSet(floor) && isSet(total_floor)) return `${floor} 樓 / ${total_floor} 層`;
  else if (detail_floor) return detail_floor;
  else return '-';
};

export const getBuildingAge = ({
  year_of_building_completion: buildingYear,
  month_of_building_completion: buildingMonth,
}: IListingInBuyPage) => {
  if (!buildingYear || !buildingMonth) return '-';

  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;

  if (currentYear - buildingYear == 0 && currentMonth - buildingMonth <= 0) {
    return '預計' + buildingYear + '完工';
  } else if (currentYear - buildingYear == 0 && currentMonth - buildingMonth > 0) {
    return `${currentMonth - buildingMonth} 個月`;
  } else if (currentYear - buildingYear < 0) {
    return '預計' + buildingYear + '完工';
  } else {
    return `${currentYear - buildingYear} 年`;
  }
};

export const getParkingDetail = ({ parking_detail, detail_parking }: IListingInBuyPage) => {
  const checkedDetail = parking_detail?.filter(({ num, parking_space_type }) => num && parking_space_type);
  if (checkedDetail && isNotEmptyArray(checkedDetail))
    return checkedDetail.map(({ num, parking_space_type }) => `${num} / ${parking_space_type}`);
  else if (detail_parking && isNotEmptyString(detail_parking) && detail_parking !== 'false') return [detail_parking];
  else return ['-'];
};

export const countParkingSpace = ({ parking_detail }: IListingInBuyPage) => {
  if (parking_detail) return `${parking_detail.reduce((prev, curr) => prev + parseInt(String(curr.num), 0), 0)} 個`;
  else return '';
};

export const squareFeetTranslator = (sqft: string, fixed: number = 2): string => {
  const sqftNumber = parseInt(sqft, 10);

  return Number(sqftNumber * 0.02810314980103).toFixed(fixed);
};

export const totalSizeFormatter = (
  totalSize: string | undefined | null,
  country: ListingDetail['country'] | BuildingCountry,
  simple?: boolean
): string => {
  let displaySize: string;
  if (totalSize) {
    displaySize = String(areaFormatter(totalSize, { country: country ?? 'TW' }));
    if (country === 'US' || country === 'CA') {
      if (simple) {
        displaySize = `${squareFeetTranslator(displaySize, 0)} 坪`;
      } else {
        displaySize = `${squareFeetTranslator(displaySize, 0)} 坪\n${displaySize} 平方英呎`;
      }
    } else {
      displaySize = `${displaySize} 坪`;
    }
  } else {
    displaySize = '-';
  }

  return displaySize;
};
