import React from 'react';
import { Badge, Stack } from '@chakra-ui/react';
import useT from '@/lib/t';
import { ListingState, ListingStatusEnum, StateOfListing } from '@/swagger';
import { format } from 'date-fns';

type Props = {
  state: Array<ReplaceValueType<ListingState, Date, number>>;
  status: ListingStatusEnum | null;
  sold_at: string | null;
};

const ColorScheme: Record<StateOfListing, [string, string, ({ outline?: string; opacity?: number } | undefined)?]> = {
  // [color, bg, additionalChakraStyleProps]
  [StateOfListing.EXCLUSIVE]: ['gold', 'white', { outline: '1px solid #b49961' }],
  [StateOfListing.DISCOUNT]: ['white', 'black'],
  [StateOfListing.NONE]: ['white', 'black'],
  [StateOfListing.NEW]: ['white', 'black', { opacity: 0.64 }],
  [StateOfListing.OPEN_HOUSE]: ['white', 'black', { opacity: 0.64 }],
  [StateOfListing.SOLD]: ['white', 'brand', { outline: 'brand' }],
  [StateOfListing.PENDING]: ['white', 'brand', { outline: 'brand' }],
};

export default function BadgeContainer({ state = [], sold_at }: Props) {
  const c = useT('share');
  const isSoldOut = state.some(({ state }) => state === StateOfListing.SOLD);

  const badges =
    // show one badge only if it's sold
    isSoldOut ? (
      <div>
        <Badge
          borderRadius={0}
          fontWeight='regular'
          fontSize='xs'
          color={ColorScheme[StateOfListing.SOLD][0]}
          px={2}
          py={0.5}
          ml={0}
          gap={3}
          bg={ColorScheme[StateOfListing.SOLD][1]}
          style={{ columnGap: 12, rowGap: 12, WebkitMarginStart: 0 }}
        >
          {c('sold')}
        </Badge>
        <Badge
          borderRadius={0}
          fontWeight='regular'
          fontSize='xs'
          color={'brand'}
          px={2}
          py={0.5}
          ml={0}
          gap={3}
          bg={'white'}
          style={{ columnGap: 12, rowGap: 12, WebkitMarginStart: 0 }}
        >
          {sold_at && format(new Date(sold_at), 'yyyy/MM/dd')}
        </Badge>
      </div>
    ) : (
      state
        .filter(({ expire_at }) => !expire_at || new Date(expire_at).getTime() >= Date.now())
        .map(({ state, label }) => {
          if (state === StateOfListing.NONE) return null;
          return (
            <Badge
              key={state}
              borderRadius={0}
              fontWeight='regular'
              fontSize='xs'
              color={ColorScheme[state][0]}
              px={2}
              py={0.5}
              ml={0}
              gap={3}
              bg={ColorScheme[state][1]}
              {...(ColorScheme[state][2] || {})}
              style={{ columnGap: 12, rowGap: 12, WebkitMarginStart: 0 }}
            >
              {label}
            </Badge>
          );
        })
    );

  return (
    <div className='absolute z-20 w-full top-0 left-0 leading-4'>
      <Stack direction='row' wrap='wrap' className='w-4/5' style={{ rowGap: '12px', columnGap: '12px' }} mt={3} ml={3}>
        {badges}
      </Stack>
    </div>
  );
}
